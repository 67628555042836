import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import { CONTACT_ME_PAGE_OLD } from "src/routesConstants";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Get in touch",
	subTitle: (
		<p>
			Pick a time that works, schedule a call and we can talk about your
			data problems!
		</p>
	)
};

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	const title = "Get in touch";
	const description = "Get in touch";
	const imageContact = data.imageContact.childImageSharp.fluid;

	return (
		<Layout>
			<Helmet title={config.siteTitle}>
				<script>{`
					window.SavvyCal=window.SavvyCal||function(){(SavvyCal.q=SavvyCal.q||[]).push(arguments)};
					SavvyCal('init');
					SavvyCal('inline', { link: 'piped-out/intro-call', selector: '#booking-page' });
				`}</script>
				<script
					async
					src="https://embed.savvycal.com/v1/embed.js"
				></script>
			</Helmet>
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>
			<section className="container outerContainer m-t-lg m-b-xl">
				<div id="booking-page"></div>
				<p>
					None of those times work? Fill in the form and we&apos;ll
					get back to you as soon as we can.{" "}
					<a href={CONTACT_ME_PAGE_OLD}>Get in touch.</a>
				</p>
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export const pageQuery = graphql`
	query InitialMeeting {
		# This gets the main image
		imageContact: file(
			relativePath: { eq: "images/pages/email_in_image.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 1169, maxHeight: 75) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

export default Index;
